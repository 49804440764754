import React, {useState, useEffect} from 'react'
import {Modal, Button, Form, Dropdown} from 'react-bootstrap'
import Dropzone from 'dropzone'

import config from '../config'
import {useAuth} from '../contexts/authContext'
import {
  getDocuments,
  getDocumentsByUserId,
  uploadDocuments,
  saveDocument,
  deleteDocuments,
  addRelated,
  deleteRelated,
  getRecipientsByUserId,
  getRelatedDocumentsByUserId,
} from '../contexts/service'
import {cardBodyStyle, handleRowSelect, handleMouseDown, handleMouseUp, handleMouseEnter} from './utils'
import {getFilteredData, SearchBar} from './utils/search'

const Documents = () => {
  const {user} = useAuth()
  const [documents, setDocuments] = useState([])

  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [shareSearch, setShareSearch] = useState('')

  // add mock data
  const [recipients, setRecipients] = useState([])
  const [selectedRecipients, setSelectedRecipients] = useState([])

  const [modalState, setModalState] = useState({
    show: false,
    type: '',
    content: null,
  })

  const [activeFilter, setActiveFilter] = useState(null)

  useEffect(() => {
    loadDocuments()
    loadRecipients()
  }, [user.id])

  useEffect(() => {
    Dropzone.autoDiscover = false
    const dropzoneElement = document.getElementById('mydropzone')
    if (dropzoneElement) {
      Dropzone.instances.forEach((instance) => instance.destroy())

      new Dropzone(dropzoneElement, {
        url: `${config.REACT_URL}/documents`,
        autoProcessQueue: false,
        init: function() {
          this.on('addedfile', (file) => {
            const formData = new FormData()
            formData.append('name', file.name)
            formData.append('author', user.name)
            formData.append('content', file)

            fetch(`${config.REACT_URL}/documents`, {
              method: 'POST',
              headers: {
                userid: user.id,
              },
              body: formData,
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error('Network response was not ok')
                }
                return response.json()
              })
              .then((data) => {
                this.emit('success', file, data)
                this.removeFile(file)
                loadDocuments()
              })
              .catch((error) => {
                this.emit('error', file, error)
                console.error('Error uploading document:', error)
              })
          })
        },
      })
    }
  }, [user.id])

  const loadDocuments = () => {
    const fetchDocuments = () => getDocumentsByUserId(user.id)

    fetchDocuments()
      .then((documents) => {
        setDocuments(documents)
      })
      .catch((error) => {
        console.error('Error loading documents:', error)
      })
  }

  const loadRecipients = async () => {
    console.log('Loading recipients for user:', user.id)
    const recipients = await getRecipientsByUserId(user.id).catch((error) => {
      console.error('Error loading recipients:', error)
      return []
    })

    if (recipients.length > 0) {
      setRecipients(recipients)
    }
  }

  // selection
  const handleDeleteSelected = async () => {
    const itemIds = selectedDocuments.map((item) => item.id)
    const confirmMessage =
      itemIds.length > 1
        ? 'Are you sure you want to delete the selected documents?'
        : 'Are you sure you want to delete the selected document?'
    const confirmDelete = window.confirm(confirmMessage)

    if (confirmDelete) {
      await deleteDocuments(itemIds, user.id).then(() => {
        setDocuments(documents.filter((document) => !itemIds.includes(document.id)))
        setSelectedDocuments([])
        loadDocuments()
      })
    }
  }

  const handleExport = () => {
    selectedDocuments.forEach((file) => {
      const blob = new Blob([file.content], {type: file.mime_type})
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = file.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    })
  }

  const handleShare = async (selectedRecipients) => {
    console.log('Selected Documents:', selectedDocuments)
    console.log('Selected Recipients:', selectedRecipients)

    const promises = selectedDocuments.flatMap((document) =>
      selectedRecipients.map((userId) => {
        const formData = new FormData()
        formData.append('name', document.name)
        formData.append('author', document.author)
        formData.append('content', new Blob([new Uint8Array(document.content.data)], {type: document.mime_type}))

        return fetch(`${config.REACT_URL}/documents`, {
          method: 'POST',
          headers: {
            userid: userId,
          },
          body: formData,
        }).then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
      })
    )

    const results = await Promise.all(promises)
    console.log('Results:', results)
    handleCloseModal()
    setSelectedDocuments([])
    loadDocuments()
  }

  // search
  const handleToggle = (filterName) => {
    setActiveFilter((prevFilter) => (prevFilter === filterName ? null : filterName))
  }

  const docFilters = ['Reports']
  const documentButtons = docFilters.map((name) => (
    <button key={name} className='btn btn-outline-primary btn-sm rounded-pill mt-3 mb-0 ml-4' onClick={() => handleToggle(name)}>
      {activeFilter === name && <span className='fas fa-times' />} {name}
    </button>
  ))

  const filteredDocuments = !activeFilter
    ? documents.filter((doc) => !doc.name.toLowerCase().includes('report'))
    : activeFilter
    ? getFilteredData(documents, `${activeFilter.slice(0, -1)} ${searchTerm}`)
    : getFilteredData(documents, searchTerm)

  const handleSearchChange = (searchTerm) => {
    setSearchTerm(searchTerm)
  }

  // modal
  const handleRowClick = (document) => {
    setModalState({
      show: true,
      type: 'view',
      content: document,
    })
  }

  const handleShareClick = () => {
    setModalState({
      show: true,
      type: 'share',
    })
  }

  const handleCloseModal = () => {
    setSelectedRecipients([])
    setModalState({
      show: false,
      content: null,
    })
  }

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='section-header'>
          <h1>Documents</h1>
        </div>
        <div className='section-body'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <form className='dropzone' id='mydropzone'>
                    <div className='fallback'>
                      <input name='file' type='file' multiple />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header d-flex align-items-center row w-100'>
                  <div className='col'>
                    <h4>Download</h4>
                  </div>
                  <div className='col-auto'>
                    <div className='card-header-form'>
                      <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-between align-items-center m-0 p-0'>
                  <div style={{overflowX: 'auto'}}>{documentButtons}</div>
                  <div className='input-group-btn d-flex justify-content-end align-items-center mt-3 mb-0' style={{marginRight: '38px'}}>
                    {selectedDocuments.length > 0 && <h6 className='mt-2 mr-3 mb-0'>{selectedDocuments.length} selected</h6>}
                    {selectedDocuments.length > 0 && (
                      <i className='fas fa-times text-danger mt-1 mr-3' style={{fontSize: '1.5rem'}} onClick={handleDeleteSelected}></i>
                    )}
                    <i className='fas fa-download text-primary mt-0 mr-3' style={{fontSize: '1.2rem'}} onClick={handleExport}></i>
                    <i
                      className='fas fa-external-link-alt text-primary mt-0'
                      style={{fontSize: '1.2rem'}}
                      onClick={() => handleShareClick()}
                    ></i>
                  </div>
                </div>
                <div
                  className='card-body'
                  style={{...cardBodyStyle, maxHeight: '800px'}}
                  ref={(el) => {
                    if (el) el.scrollLeft = 10
                  }}
                >
                  <table className='table table-hover'>
                    <thead>
                      <tr>
                        <th scope='col'>#</th>
                        <th scope='col'>Name</th>
                        <th scope='col' className='text-nowrap'>
                          Date Added
                        </th>
                        <th scope='col' className='text-nowrap'>
                          Uploaded By
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDocuments.map((document, index) => (
                        <tr
                          key={index}
                          onClick={() => handleRowSelect(document, selectedDocuments, setSelectedDocuments)}
                          onDoubleClick={() => handleRowClick(document)}
                          onMouseDown={() => handleMouseDown(setIsDragging)}
                          onMouseUp={() => handleMouseUp(setIsDragging)}
                          onMouseEnter={() => handleMouseEnter(document, isDragging, selectedDocuments, setSelectedDocuments)}
                          className={selectedDocuments.includes(document) ? 'table-secondary' : ''}
                        >
                          <th scope='row' data-label='#'>
                            {index + 1}
                          </th>
                          <td data-label='Name'>{document.name}</td>
                          <td data-label='Date Added'>{new Date(document.created_at).toLocaleDateString()}</td>
                          <td data-label='Uploaded By'>{document.author ? document.author : ''}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {modalState.show && modalState.content && modalState.type === 'view' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>{modalState.content.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src={`data:${modalState.content.mime_type};base64,${Buffer.from(modalState.content.content).toString('base64')}`}
              frameBorder='0'
              width='100%'
              height='600px'
            ></iframe>
          </Modal.Body>
        </Modal>
      )}
      {modalState.show && modalState.type === 'share' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered size='sm'>
          <Modal.Header closeButton>
            <Modal.Title>{`Share ${selectedDocuments.length < 2 ? 'Document' : `${selectedDocuments.length} Documents`}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId='formRecipients'>
                <Form.Label>Recipients {selectedRecipients.length > 0 && `+${selectedRecipients.length} selected`}</Form.Label>
              </Form.Group>
              <Form.Group controlId='formSearch'>
                <Form.Control
                  type='text'
                  placeholder='Search Recipients'
                  value={shareSearch}
                  onChange={(e) => setShareSearch(e.target.value)}
                  className='mb-2'
                />
              </Form.Group>
              <div style={{height: '220px', overflowY: 'auto'}}>
                {getFilteredData(recipients, shareSearch)
                  .sort((a, b) => {
                    const aSelected = selectedRecipients.includes(a.id)
                    const bSelected = selectedRecipients.includes(b.id)
                    if (aSelected === bSelected) return 0
                    return aSelected ? -1 : 1
                  })
                  .map((recipient) => {
                    const isSelected = selectedRecipients.includes(recipient.id)
                    return (
                      <div
                        key={recipient.id}
                        className='fs-6 mb-2'
                        onClick={() => {
                          if (isSelected) {
                            setSelectedRecipients(selectedRecipients.filter((id) => id !== recipient.id))
                          } else {
                            setSelectedRecipients([...selectedRecipients, recipient.id])
                          }
                        }}
                      >
                        {recipient.name}
                        {isSelected && <i className='fas fa-times text-danger ml-2'></i>}
                      </div>
                    )
                  })}
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='primary'
              onClick={() => handleShare(selectedRecipients)}
              disabled={selectedDocuments.length < 1 || selectedRecipients.length < 1}
            >
              Share
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default Documents
