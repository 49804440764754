import React, {useState, useEffect} from 'react'
import {Modal, Dropdown, Button} from 'react-bootstrap'
import {jsPDF} from 'jspdf'
import $ from 'jquery'
import 'summernote/dist/summernote-bs4.css'
import 'summernote/dist/summernote-bs4.js'
import {useAuth} from '../contexts/authContext.js'
import {getRecipientsByUserId, getDocumentsByUserId, saveDocument} from '../contexts/service.js'
import {truncateText} from './utils'
import config from '../config.js'

const Editor = () => {
  const {user} = useAuth()
  const [recipients, setRecipients] = useState([])
  const [selectedRecipient, setSelectedRecipient] = useState('')
  const [documentData, setDocumentData] = useState({
    content: '',
  })
  const [documents, setDocuments] = useState([])

  const [modalState, setModalState] = useState({
    show: false,
    type: '',
    content: null,
  })

  useEffect(() => {
    const loadRecipients = async () => {
      if (user && user.id) {
        const recipientData = await getRecipientsByUserId(user.id)
        if (recipientData) {
          setRecipients(recipientData)
        }
      }
    }

    loadRecipients()
  }, [user])

  useEffect(() => {
    const savedDocumentData = localStorage.getItem('documentData')
    if (savedDocumentData) {
      const parsedData = JSON.parse(savedDocumentData)
      setDocumentData(parsedData)
      initializeSummernote(parsedData.content)
    } else {
      initializeSummernote('')
    }

    loadRecentDocuments()

    return () => {
      const currentContent = $('#summernote').summernote('code')
      const newDocumentData = {...documentData, content: currentContent}
      updateDocumentData(newDocumentData)
    }
  }, [])

  const initializeSummernote = (content) => {
    $('#summernote')
      .summernote({
        height: 380,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['para', ['paragraph']],
        ],
        callbacks: {
          onChange: function(contents) {
            const plainText = $('<div>')
              .html(contents)
              .text()
            const newDocumentData = {...documentData, content: plainText}
            updateDocumentData(newDocumentData)
          },
        },
      })
      .summernote('code', content)
  }

  const updateDocumentData = (newData) => {
    setDocumentData(newData)
    localStorage.setItem('documentData', JSON.stringify(newData))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const pdfBlob = convertToPDF(documentData)
    if (!pdfBlob) {
      throw new Error('PDF conversion failed')
    }

    const formData = new FormData()
    const readableDate = getReadableDate()
    const fileName = user.group === 'client' ? `Request ${readableDate}.pdf` : `Report ${readableDate}.pdf`
    formData.append('content', pdfBlob, fileName)
    formData.append('name', fileName)
    formData.append('author', user.name)

    const admin = recipients.find((recipient) => recipient.group === 'admin')
    await Promise.all([saveDocument(formData, user.id), saveDocument(formData, selectedRecipient), saveDocument(formData, admin?.id)])
    resetForm()
  }

  const convertToPDF = (data) => {
    const doc = new jsPDF()
    doc.setFontSize(12)

    const pageWidth = doc.internal.pageSize.getWidth()
    const margin = 10
    const maxLineWidth = pageWidth - margin * 2

    const contentLines = doc.splitTextToSize(data.content, maxLineWidth)
    doc.text(contentLines, 10, 30)

    return doc.output('blob')
  }

  const getReadableDate = () => {
    const date = new Date()
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
  }

  const resetForm = () => {
    setDocumentData({subject: '', content: ''})
    setSelectedRecipient('')
    $('#summernote').summernote('reset')
    localStorage.removeItem('documentData')
  }

  const loadRecentDocuments = async () => {
    const data = await getDocumentsByUserId(user.id)
    const reportDocuments = data.filter((doc) => doc.name.toLowerCase().includes('report'))
    reportDocuments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    const recentReportDocuments = reportDocuments.slice(0, 10)
    console.log(recentReportDocuments)
    setDocuments(recentReportDocuments)
    console.log(data)
  }

  const filteredRecipients = recipients.filter((recipient) => recipient.name.toLowerCase() !== 'admin')

  // modal
  const handleDocClick = (document) => {
    setModalState({
      show: true,
      type: 'view',
      content: document,
    })
  }

  const handleCloseModal = () => {
    setModalState({
      show: false,
      content: null,
    })
  }

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='section-header'>
          <h1>{user.group === 'client' ? 'Requests' : 'Reports'}</h1>
        </div>
        <div className='section-body'>
          <div className='row'>
            {/* Editor Card */}
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='row justify-content-center '>
                    <div className='col-lg-10 col-md-10 col-sm-12'>
                      <div className='form-group'>
                        <label>Recipient</label>
                        <div className='d-flex align-items-center'>
                          <div className='flex-grow-1'>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant='primary'
                                id='dropdown-basic'
                                className='full-width-dropdown'
                                style={{fontSize: '12.5px'}}
                              >
                                {selectedRecipient
                                  ? recipients.find((r) => r.id === selectedRecipient)?.name || 'Select a recipient'
                                  : 'Select a recipient'}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className='full-width-dropdown text-center'>
                                {filteredRecipients.map((recipient) => (
                                  <Dropdown.Item
                                    key={recipient.id}
                                    onClick={() => setSelectedRecipient(recipient.id)}
                                    style={{fontSize: '12.5px'}}
                                  >
                                    {recipient.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <button className='btn btn-primary ml-3 d-flex align-items-center' onClick={handleSubmit}>
                            <div className='mx-n0 mr-1' style={{fontSize: '12.5px'}}>
                              Send
                            </div>
                            <i className='fas fa-arrow-up' />
                          </button>
                        </div>
                      </div>
                      <div className='form-group'></div>
                      <div className='form-group'>
                        <label>Content</label>
                        <div id='summernote' className='summernote-simple form-control'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12'>
              <div className='card'>
                <div className='card-header '>
                  <h4>Recents</h4>
                </div>
                <div className='card-body' style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                  {documents.map((document, index) => (
                    <div key={index} className='mr-3' style={{display: 'inline-block'}} onDoubleClick={() => handleDocClick(document)}>
                      <div className='card justify-content-center align-items-center' style={{width: '200px'}}>
                        <h6 className='card-title'>{document.name}</h6>
                        <h6 className='card-title mb-n1 d-flex justify-content-start'>
                          {document.author === user.name ? `Sent by Me` : `Sent from ${truncateText(document.author)}`}
                        </h6>
                        <div className='card-body' style={{height: '200px', overflow: 'auto'}}>
                          <iframe
                            src={`data:${document.mime_type};base64,${Buffer.from(document.content).toString('base64')}`}
                            frameBorder='0'
                            width='100%'
                            height='200px'
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {modalState.show && modalState.content && modalState.type === 'view' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>{modalState.content.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src={`data:${modalState.content.mime_type};base64,${Buffer.from(modalState.content.content).toString('base64')}`}
              frameBorder='0'
              width='100%'
              height='600px'
            ></iframe>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default Editor
