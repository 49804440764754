export const Data = {
  menus: [
    // {
    //   header: true,
    //   name: "Dashboard",
    // },
    {
      name: "Dashboard",
      icon: "fas fa-fire",
      url: "/",
    },
    {
      name: "People",
      icon: "fas fa-users",
      url: "/users",
    },
    // {
    //   header: true,
    //   name: "Pages",
    // },
    {
      name: "Schedule",
      icon: "fas fa-calendar",
      url: "/calendar",
    },
    {
      name: "Documents",
      icon: "fas fa-file",
      url: "/documents",
    },
    {
      name: "Reports",
      icon: "fas fa-pencil-ruler",
      url: "/editor",
    },
    // {
    //   dropdown: true,
    //   active: false,
    //   name: "Auth",
    //   icon: "far fa-user",
    //   children: [
    //     {
    //       name: "Forgot Password",
    //       url: "/auth/forget-password",
    //       active: false,
    //     },
    //     {
    //       name: "Login",
    //       url: "/auth/login",
    //       active: false,
    //     },
    //     {
    //       name: "Reset Password",
    //       url: "/auth/reset-password",
    //       active: false,
    //     },
    //   ],
    // },
    // {
    //   dropdown: true,
    //   active: false,
    //   name: "Errors",
    //   icon: "fas fa-exclamation",
    //   children: [
    //     {
    //       name: "503",
    //       url: "/error/503",
    //       active: false,
    //     },
    //     {
    //       name: "403",
    //       url: "/error/403",
    //       active: false,
    //     },
    //     {
    //       name: "404",
    //       url: "/error/404",
    //       active: false,
    //     },
    //     {
    //       name: "500",
    //       url: "/error/500",
    //       active: false,
    //     },
    //   ],
    // },
  ],
}
