import React, {useState, useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import {useAuth} from '../../contexts/authContext'
import {Modal, Button, Form, Dropdown} from 'react-bootstrap'
import {formatAddress} from '../../pages/utils'
import {updateUser} from '../../contexts/service'

export const UserDropdown = () => {
  const {user, setUser, logout} = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [logTime, setLogTime] = useState(null)

  const [userDetail, setUserDetail] = useState({
    img: user?.image
      ? `data:${user.image.mimetype};base64,${Buffer.from(user.image.data).toString('base64')}`
      : 'https://placehold.co/42/000000/FFF?text=Profile',
    name: user?.name ?? '',
    email: user?.email ?? '',
    phone: user?.phone ?? '',
    address: user?.address ?? '',
  })

  useEffect(() => {
    const storedLogTime = localStorage.getItem('logTime')
    if (storedLogTime) {
      setLogTime(storedLogTime)
    }
  }, [])

  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  const handleProfileChange = (event) => {
    const {name, value, files} = event.target
    if (files && files[0].size > 5 * 1024 * 1024) {
      alert('Image size should not exceed 5MB')
      return
    }
    setUserDetail((prevUserDetail) => ({
      ...prevUserDetail,
      [name]: files ? URL.createObjectURL(files[0]) : value,
      ...(files && {file: files[0]}), // Only adds the file property if files is present
    }))
  }

  const handleSaveProfile = () => {
    const formData = new FormData()

    // Compare userDetail with user and only include changed fields
    if (userDetail.name !== user?.name) formData.append('name', userDetail.name)
    if (userDetail.email !== user?.email) formData.append('email', userDetail.email)
    if (userDetail.phone !== user?.phone) formData.append('phone', userDetail.phone)
    if (userDetail.address !== user?.address) formData.append('address', userDetail.address)
    if (userDetail.file) formData.append('image', userDetail.file) // Append the file correctly

    // Log the FormData content for debugging purposes
    for (const pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]) // This should log the key-value pairs
    }

    if (user && user.id) {
      updateUser(formData, user.id)
        .then((updatedUserData) => {
          setUser((prevUser) => ({
            ...prevUser,
            ...updatedUserData,
          }))
          setShowModal(false)
        })
        .catch((error) => {
          console.error('Update failed:', error.message)
        })
    }
  }

  const calculateLogTime = () => {
    if (!logTime) return 'Unknown'
    const now = new Date()
    const diff = Math.floor((now - new Date(logTime)) / 60000) // Difference in minutes
    if (diff < 60) return `${diff} minutes`
    const hours = Math.floor(diff / 60)
    return `${hours} hours`
  }

  return (
    <>
      <Dropdown align='end'>
        <Dropdown.Toggle as='a' className='nav-link dropdown-toggle mt-1' id='navbarDropdown'>
          <img alt='Profile' src={userDetail.img} className='rounded-circle me-2' width='42' height='42' />
          <div className='d-none d-lg-inline-block text-white'>{userDetail.name}</div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>Logged in {calculateLogTime()} ago</Dropdown.Header>
          {user &&
            user.datas &&
            user.datas.map((data, idata) => (
              <Dropdown.Item key={idata} as={NavLink} to={data.link} activeClassName='active'>
                <i className={data.icode} /> {data.title}
              </Dropdown.Item>
            ))}
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleShowModal}>Edit Profile</Dropdown.Item>
          <Dropdown.Item className='text-danger' onClick={logout}>
            <i className='defaultLogoutIcon' /> Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3'>
              <div className='d-flex justify-content-center'>
                <img src={userDetail.img} alt='Profile' className='mt-2 mb-2 rounded-circle' width='100' height='100' />
              </div>
              <Form.Label>Profile Image</Form.Label>
              <Form.Control type='file' accept='image/*' onChange={handleProfileChange} name='img' />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Profile Name</Form.Label>
              <Form.Control type='text' value={userDetail.name} onChange={handleProfileChange} name='name' />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control type='email' value={userDetail.email} onChange={handleProfileChange} name='email' />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Phone</Form.Label>
              <Form.Control type='text' value={userDetail.phone} onChange={handleProfileChange} name='phone' />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Address</Form.Label>
              <Form.Control type='text' value={formatAddress(userDetail.address)} onChange={handleProfileChange} name='address' />
            </Form.Group>
            <Form.Group className='d-flex justify-content-end'>
              <Button variant='primary' onClick={handleSaveProfile}>
                Save Changes
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserDropdown
