import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useAuth} from '../../contexts/authContext'
import {getRecipientsByUserId, getDocumentsByUserId} from '../../contexts/service'
import {cardStyle, cardBodyStyle, UserCard, InfoCard, DocumentPreview} from '../utils'

const DashClient = () => {
  const {user} = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [document, setDocument] = useState(null)
  const [recipients, setRecipients] = useState([])

  useEffect(() => {
    if (user) {
      fetchData()
    }
  }, [user])

  const fetchData = async () => {
    Promise.all([getRecipientsByUserId(user.id), getDocumentsByUserId(user.id)])
      .then(([recipientData, documentData]) => {
        if (recipientData) {
          const filteredRecipients = recipientData.filter((recipient) => recipient.name.toLowerCase() !== 'admin')
          setRecipients(filteredRecipients)
        }
        if (documentData) {
          setDocument(documentData[0])
        }
      })
      .catch((error) => {
        console.log('No data.')
      })
  }

  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='row'>
          <UserCard data={recipients} title='Assigned Care Info' />
          <InfoCard data={{}} title='Activities Info' />
        </div>
        <div className='row'>
          <DocumentPreview document={document} handleShowModal={handleShowModal} />
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='card card-statistic-2' style={{cardStyle}}>
              <div className='card-header'>
                <h4>Reminders</h4>
              </div>
              <div className='card-body' style={{cardBodyStyle}}>
                <ul className='mt-2'>
                  <li className='mt-2'>take vitamins</li>
                  <li className='mt-2'>take medication</li>
                  <li className='mt-2'>drink water</li>
                  <li className='mt-2'>eat meals</li>
                  <li className='mt-2'>eat snacks</li>
                  <li className='mt-2'>shower</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <canvas height='400'></canvas>
      </div>

      {document && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{document.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {document.mime_type === 'application/pdf' ? (
              <iframe src={`data:application/pdf;base64,${btoa(document.content)}`} width='100%' height='500px'></iframe>
            ) : (
              <pre>{document.content}</pre>
            )}
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default DashClient
