import React, {createContext, useContext, useState, useEffect, useMemo} from 'react'
import config from '../config'
import {getUserById} from './service'
import {jwtDecode} from 'jwt-decode'

const AuthContext = createContext({
  user: null,
  setUser: () => {},
  login: async () => false,
  logout: async () => {},
  isAuthenticated: false,
  loading: true,
})

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user')
    return storedUser ? JSON.parse(storedUser) : null
  })
  const [isAuthenticated, setIsAuthenticated] = useState(!!user)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        const decodedToken = jwtDecode(token)
        if (decodedToken.exp * 1000 > Date.now()) {
          setIsAuthenticated(true)
          fetchAndUpdateUser(decodedToken.userId)
        } else {
          localStorage.removeItem('token')
          setLoading(false)
        }
      } catch (error) {
        console.error('Token decoding error:', error)
        localStorage.removeItem('token')
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [])

  const fetchAndUpdateUser = async (userId) => {
    try {
      const userData = await getUserById(userId)
      setUser(userData)
      localStorage.setItem('user', JSON.stringify(userData))
      console.log('User updated:', userData.email)
    } catch (error) {
      console.error('Fetch user data error:', error)
    } finally {
      setLoading(false)
    }
  }

  const login = async (email, password) => {
    try {
      // Make a POST request to the authentication endpoint
      const response = await fetch(`${config.REACT_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email, password}),
      })

      const data = await response.json()
      if (response.ok && data?.token) {
        const decodedToken = jwtDecode(data.token)
        setIsAuthenticated(true)

        // Store the token and login time in localStorage
        localStorage.setItem('token', data.token)
        localStorage.setItem('logTime', new Date().toISOString())

        await fetchAndUpdateUser(decodedToken.userId)
        return {isAuthenticated: true, message: ''}
      } else {
        return {isAuthenticated: false, message: data.message}
      }
    } catch (error) {
      console.error('Login error:', error)
      return {isAuthenticated: false, message: 'Login error'}
    }
  }

  const logout = async () => {
    try {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('logTime')
      setUser(null)
      setIsAuthenticated(false)
    } catch (error) {
      console.error('Logout error:', error)
    }
  }

  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      login,
      logout,
      isAuthenticated,
      loading,
    }),
    [user, isAuthenticated, loading]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
