import React, {useEffect, useRef, useState, useCallback} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid/index.js'
import interactionPlugin from '@fullcalendar/interaction/index.js'
import {Modal, Button, Form, Dropdown, ListGroup} from 'react-bootstrap'
import {useAuth} from '../contexts/authContext'
import {
  getEventsByUserId,
  createEvent,
  updateEvent,
  deleteEvents,
  addRelated,
  deleteRelated,
  getRecipientsByUserId,
  getRelatedEventsByUserId,
  fetchLocationPosition,
} from '../contexts/service'
import {
  cardBodyStyle,
  handleRowSelect,
  handleMouseDown,
  handleMouseUp,
  handleMouseEnter,
  daysOfWeek,
  convertUTCToLocalTime,
  parseAddressSugg,
  splitAddress,
  formatDateWithTime,
  formatDateForInput,
  formatTimeForInput,
} from './utils'
import {getFilteredData, SearchBar} from './utils/search'
import {parseRRule, formatDate, generateOccurrencesWithExdates, addExdatesForFutureOccurrences, isFirstOccurrence} from './utils/recurrence'

import {Map, Marker} from 'pigeon-maps'
import debounce from 'lodash.debounce'

const Calendar = () => {
  const {user} = useAuth()
  const [events, setEvents] = useState([])
  const [hoveredDate, setHoveredDate] = useState(null)
  const calendarRef = useRef(null)

  const [selectedEvents, setSelectedEvents] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [shareSearch, setShareSearch] = useState('')

  const [recipients, setRecipients] = useState([])
  const [selectedRecipients, setSelectedRecipients] = useState([])

  const [modalState, setModalState] = useState({
    show: false,
    type: '',
    isEditing: false,
    location: false,
    markerPosition: [],
    suggestions: [],
    content: {
      id: null,
      name: '',
      details: '',
      start_time: '12:00',
      end_time: '13:00',
      rrule: '',
      location: {
        position: [],
        address: '',
      },
      invitees: [],
    },
    startDate: new Date(),
    endDate: new Date(),
    deletionMode: '',
    updateMode: '',
  })

  const [activeFilter, setActiveFilter] = useState(null)

  useEffect(() => {
    loadEvents()
    loadRecipients()
  }, [user.id])

  const loadEvents = () => {
    getEventsByUserId(user.id)
      .then((result) => {
        const events = result.events || []
        const related = result.related || []

        // Combine user-created events with related (shared) events
        const combinedEvents = [...events, ...related]
        const expandedEvents = expandRecurringEvents(combinedEvents)

        // Sort the events by start time
        setEvents(expandedEvents.sort((a, b) => new Date(a.start_time) - new Date(b.start_time)))
      })
      .catch((error) => {
        console.error('Unexpected error loading events:', error)
      })
  }

  const loadRecipients = async () => {
    const recipients = await getRecipientsByUserId(user.id)
    if (recipients.length > 0) {
      setRecipients(recipients)
    }
  }

  // utils
  const getViewDateRange = () => {
    const calendarApi = calendarRef.current.getApi()
    const view = calendarApi.view
    return {start: view.activeStart, end: view.activeEnd}
  }

  const expandRecurringEvents = (events) =>
    events.flatMap((event) => {
      if (!event.rrule) {
        return {
          ...event,
          id: event.id,
          start_time: event.start_time,
          end_time: event.end_time,
        }
      }

      const occurrences = generateOccurrencesWithExdates(event.rrule, new Date(event.start_time))
      return occurrences.map((date) => {
        const start = new Date(date)
        const end = new Date(date)
        end.setMinutes(end.getMinutes() + (new Date(event.end_time) - new Date(event.start_time)) / 60000)
        return {
          ...event,
          id: `${event.id}-${start.toISOString()}`, // Include occurrence date in ID
          start_time: start,
          end_time: end,
        }
      })
    })

  // selection
  const handleDateMouseEnter = (date) => {
    setHoveredDate(date.toISOString().split('T')[0])
  }

  const handleDateMouseLeave = () => {
    setHoveredDate(null)
  }

  const handleDeleteSelected = async (mode) => {
    const deletePromises = selectedEvents.map((event) => {
      if (event.rrule) {
        const baseId = event.id
          .split('-')
          .slice(0, 5)
          .join('-')

        if (mode === 'all') {
          if (isFirstOccurrence(event, events)) {
            return deleteEvents([baseId], user.id)
          } else {
            const futureExdates = addExdatesForFutureOccurrences(event, baseId, events)
            const selectedEventExdate =
              new Date(event.start_time)
                .toISOString()
                .replace(/[-:]/g, '')
                .split('.')[0] + 'Z'
            const allExdates = [...futureExdates, selectedEventExdate]

            let rruleObj = parseRRule(event.rrule)
            rruleObj.EXDATE = rruleObj.EXDATE ? [...rruleObj.EXDATE, ...allExdates] : allExdates

            const exdates = rruleObj.EXDATE.map((date) =>
              typeof date === 'string' && date.match(/^\d{8}T\d{6}Z$/) ? date : formatDate(date)
            )
            const newRRule = Object.entries({...rruleObj, EXDATE: exdates})
              .map(([key, value]) => `${key}=${Array.isArray(value) ? value.join(',') : value}`)
              .join(';')

            return updateEvent({id: baseId, rrule: newRRule}, user.id)
          }
        } else {
          const exDate =
            new Date(event.start_time)
              .toISOString()
              .replace(/[-:]/g, '')
              .split('.')[0] + 'Z'

          let rruleObj = parseRRule(event.rrule)
          rruleObj.EXDATE = rruleObj.EXDATE ? [...rruleObj.EXDATE, exDate] : [exDate]

          const exdates = rruleObj.EXDATE.map((date) =>
            typeof date === 'string' && date.match(/^\d{8}T\d{6}Z$/) ? date : formatDate(date)
          )
          const newRRule = Object.entries({...rruleObj, EXDATE: exdates})
            .map(([key, value]) => `${key}=${Array.isArray(value) ? value.join(',') : value}`)
            .join(';')

          return updateEvent({id: baseId, rrule: newRRule}, user.id)
        }
      } else {
        return deleteEvents([event.id], user.id)
      }
    })

    await Promise.all(deletePromises)
      .then(() => {
        handleCloseModal()
        setSelectedEvents([])
        loadEvents()
      })
      .catch((error) => {
        console.error('Error during event deletion:', error.message)
      })
  }

  const handleUpdateAllOccurrences = async (newEvent, userId) => {
    if (!newEvent.rrule) {
      console.error('rrule is undefined or null')
      return
    }

    // Parse and reconstruct rrule with FREQ, INTERVAL, and UNTIL
    const {FREQ, INTERVAL, UNTIL} = parseRRule(newEvent.rrule)
    newEvent.rrule = [FREQ && `FREQ=${FREQ}`, INTERVAL && `INTERVAL=${INTERVAL}`, UNTIL && `UNTIL=${UNTIL}`].filter(Boolean).join(';')

    return await updateEvent(newEvent, userId)
  }

  const handleUpdateOneOccurrence = async (newEvent, userId) => {
    if (!newEvent.rrule) {
      console.error('rrule is undefined or null')
      return
    }

    // Add EXDATE to exclude the specific occurrence
    const exDate =
      new Date(modalState.startDate)
        .toISOString()
        .replace(/[-:]/g, '')
        .split('.')[0] + 'Z'
    const rruleObj = parseRRule(newEvent.rrule)
    rruleObj.EXDATE = [...(rruleObj.EXDATE || []), exDate].filter((date) => /^\d{8}T\d{6}Z$/.test(date))

    const newRRule = Object.entries(rruleObj)
      .map(([key, value]) => `${key}=${Array.isArray(value) ? value.join(',') : value}`)
      .join(';')

    await updateEvent({id: newEvent.id, rrule: newRRule}, userId)
    await createEvent({...newEvent, rrule: ''}, userId)
  }

  const handleUpdateSelected = async (mode) => {
    const baseId = modalState.content.id
      .split('-')
      .slice(0, 5)
      .join('-')

    const newEvent = {
      id: baseId,
      name: modalState.content.name,
      start_time: formatDateWithTime(modalState.startDate, modalState.content.start_time),
      end_time: formatDateWithTime(modalState.endDate, modalState.content.end_time),
      description: modalState.content.details,
      location: modalState.content.location?.address || '',
      rrule: modalState.content.rrule,
      invitees: modalState.content.invitees || [],
    }

    if (mode === 'one') {
      await handleUpdateOneOccurrence(newEvent, modalState.content.id, user.id)
    } else if (mode === 'all') {
      await handleUpdateAllOccurrences(newEvent, user.id)
    }

    handleCloseModal()
    loadEvents()
  }

  const handleShare = async (selectedRecipients) => {
    const items = selectedRecipients.map((recipient) => {
      const relatedIds = selectedEvents.map((event) => event.id)
      return {
        userid: recipient,
        relatedIds,
        relationship_type: 'event',
      }
    })

    return addRelated(items, user.id)
      .then((results) => {
        handleCloseModal()
        setSelectedEvents([])
        loadEvents()
      })
      .catch((error) => {
        console.error('Error saving Events:', error)
      })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const {
      isEditing,
      content: {id, name, start_time, end_time, details, location, invitees = [], rrule},
      startDate,
      endDate,
    } = modalState

    console.log(location)

    console.log('Before formatting:')
    console.log('Start Date:', startDate, 'Start Time:', start_time)
    console.log('End Date:', endDate, 'End Time:', end_time)

    const startDateTime = formatDateWithTime(startDate, start_time)
    const endDateTime = formatDateWithTime(endDate, end_time)

    console.log('After formatting:')
    console.log('startDateTime:', startDateTime)
    console.log('endDateTime:', endDateTime)

    if (startDateTime > endDateTime) {
      alert('Start time cannot be after end time')
      return
    }

    // Prepare invitees with deleted flags
    const formattedInvitees = invitees.map((invitee) => ({
      user_id: invitee.user_id,
      checkin_time: invitee.checkin_time || null,
      checkout_time: invitee.checkout_time || null,
      deleted: invitee.deleted || false, // Include the deleted flag
    }))

    const eventDetails = {
      id,
      name,
      start_time: startDateTime.toISOString(),
      end_time: endDateTime.toISOString(),
      description: details,
      location: location.address,
      invitees: formattedInvitees, // Use formatted invitees with deleted flags
      ...(rrule && {rrule}), // Include rrule if present
    }

    const updateEventsList = (updatedEvent) => {
      setEvents((prevEvents) => {
        const updatedList = isEditing
          ? prevEvents.map((event) => (event.id === updatedEvent.id ? updatedEvent : event))
          : [...prevEvents, updatedEvent]

        // Sort the updated list by start_time
        updatedList.sort((a, b) => new Date(a.start_time) - new Date(b.start_time))

        return updatedList
      })
    }

    const saveEvent = async () => {
      let savedEvent
      if (isEditing) {
        if (!rrule) {
          savedEvent = await updateEvent(eventDetails, user.id)
        } else {
          handleUpdateClick()
          savedEvent = await Promise.resolve()
        }
      } else {
        if (rrule) {
          eventDetails.rrule = rrule

          const isValidRRule = (rrule) => rrule.split(';').some((part) => part.startsWith('FREQ='))

          if (!isValidRRule(rrule)) {
            return Promise.reject(new Error('Invalid rrule: must include FREQ.'))
          }
        }

        savedEvent = await createEvent(eventDetails, user.id)
      }

      if (savedEvent) {
        updateEventsList(savedEvent)
      }

      return savedEvent
    }

    saveEvent()
      .catch((error) => {
        console.error('Error saving event:', error)
        alert('Failed to save the event. Please try again.') // Optional: User-friendly error message
      })
      .finally(() => {
        handleCloseModal()
        setSelectedEvents([])
        loadEvents()
      })
  }

  // search
  const handleToggle = (filterName) => {
    setActiveFilter((prevFilter) => (prevFilter === filterName ? null : filterName))
  }

  const eventFilters = ['Past Events']
  const eventButtons = eventFilters.map((name) => (
    <button key={name} className='btn btn-outline-primary btn-sm rounded-pill mt-3 mb-0 ml-4' onClick={() => handleToggle(name)}>
      {activeFilter === name && <span className='fas fa-times' />} {name}
    </button>
  ))

  const handleSearchChange = (searchTerm) => {
    setSearchTerm(searchTerm)
  }

  const eventsForMonth = getFilteredData(events, searchTerm).filter((event) => {
    const eventDate = new Date(event.start_time)
    const {start, end} = getViewDateRange()
    return eventDate >= start && eventDate < end
  })

  const filteredEvents = eventsForMonth.filter((event) => {
    const eventEndTime = new Date(event.end_time)
    const currentDate = new Date()

    if (activeFilter === 'Past Events') {
      return true
    } else {
      const isUpcoming = eventEndTime >= currentDate
      return isUpcoming
    }
  })

  // modal
  const handleInputChange = (e) => {
    const {name, value} = e.target
    setModalState((prevState) => {
      let newContent = {...prevState.content}
      let newStartDate = prevState.startDate
      let newEndDate = prevState.endDate

      if (name === 'start_time') {
        newContent.start_time = value
      } else if (name === 'end_time') {
        newContent.end_time = value
      } else if (name === 'startDate') {
        if (value) {
          const [year, month, day] = value.split('-').map(Number)
          newStartDate = new Date(year, month - 1, day)
        } else {
          newStartDate = null
        }
      } else if (name === 'endDate') {
        if (value) {
          const [year, month, day] = value.split('-').map(Number)
          newEndDate = new Date(year, month - 1, day)
        } else {
          newEndDate = null
        }
      } else if (name === 'location') {
        newContent.location = {
          ...newContent.location,
          address: value,
        }
        if (value.length > 5) {
          fetchSuggestions(value)
        }
      } else {
        newContent[name] = value
      }

      return {
        ...prevState,
        content: newContent,
        startDate: newStartDate,
        endDate: newEndDate,
        suggestions: [],
      }
    })
  }

  // Combined function to handle assigning and removing invitees
  const handleInviteeChange = (selectedInviteeId, action) => {
    setModalState((prevState) => {
      const invitees = prevState.content.invitees || []
      const inviteeIndex = invitees.findIndex((invitee) => invitee.user_id === selectedInviteeId)
      const selectedInvitee = recipients.find((recipient) => recipient.id === selectedInviteeId)

      if (action === 'assign') {
        if (selectedInvitee && inviteeIndex === -1) {
          // Add new invitee with deleted: false
          return {
            ...prevState,
            content: {
              ...prevState.content,
              invitees: [
                ...invitees,
                {
                  user_id: selectedInvitee.id,
                  user: selectedInvitee.name,
                  deleted: false, // Ensure deleted is false when adding
                },
              ],
            },
          }
        } else if (inviteeIndex !== -1 && invitees[inviteeIndex].deleted) {
          // Re-assign a previously removed invitee by setting deleted to false
          const updatedInvitees = [...invitees]
          updatedInvitees[inviteeIndex] = {
            ...updatedInvitees[inviteeIndex],
            deleted: false,
          }
          return {
            ...prevState,
            content: {
              ...prevState.content,
              invitees: updatedInvitees,
            },
          }
        }
      } else if (action === 'remove') {
        if (inviteeIndex !== -1 && !invitees[inviteeIndex].deleted) {
          // Mark the invitee as deleted
          const updatedInvitees = [...invitees]
          updatedInvitees[inviteeIndex] = {
            ...updatedInvitees[inviteeIndex],
            deleted: true, // Set deleted to true instead of removing
          }
          return {
            ...prevState,
            content: {
              ...prevState.content,
              invitees: updatedInvitees,
            },
          }
        }
      }

      // If no changes are made, return the previous state
      return prevState
    })
  }

  const handleRRuleChange = (e) => {
    const {name, value} = e.target
    setModalState((prevState) => {
      const updatedRRule = {...prevState.content.rruleObj, [name]: value}

      if (name === 'UNTIL') {
        const untilDate = new Date(value)
        const eventStartDate = new Date(prevState.startDate)
      }

      // Handle FREQ and BYDAY (only for WEEKLY frequency)
      if (name === 'FREQ' && value !== 'WEEKLY') {
        delete updatedRRule.BYDAY // Remove BYDAY if the frequency is not weekly
      }

      // Build rrule string dynamically based on updatedRRule
      let rruleString = Object.entries(updatedRRule)
        .map(([key, val]) => `${key}=${Array.isArray(val) ? val.join(',') : val}`)
        .join(';')

      return {
        ...prevState,
        content: {
          ...prevState.content,
          rrule: rruleString,
          rruleObj: updatedRRule,
        },
      }
    })
  }

  const handleDayOfWeekChange = (e) => {
    const selectedDay = e.target.value
    const isChecked = e.target.checked
    setModalState((prevState) => {
      const currentDays = prevState.content.rruleObj.BYDAY || []
      const updatedDays = isChecked ? [...currentDays, selectedDay] : currentDays.filter((day) => day !== selectedDay)

      const updatedRRule = {
        ...prevState.content.rruleObj,
        BYDAY: updatedDays,
      }

      const rruleString = Object.entries(updatedRRule)
        .map(([key, val]) => `${key}=${Array.isArray(val) ? val.join(',') : val}`)
        .join(';')

      return {
        ...prevState,
        content: {
          ...prevState.content,
          rrule: rruleString,
          rruleObj: updatedRRule,
        },
      }
    })
  }

  const handleDateClick = (info) => {
    setModalState({
      show: true,
      type: 'edit',
      isEditing: false,
      content: {
        id: null,
        name: '',
        details: '',
        start_time: '12:00',
        end_time: '13:00',
        rrule: '',
        rruleObj: {},
        location: {
          address: '',
        },
      },
      startDate: info.date,
      endDate: info.date,
    })
  }

  const handleEventClick = async (info) => {
    const event = events.find((e) => e.id === info.event.id)
    if (event) {
      const rruleObj = event.rrule ? parseRRule(event.rrule) : {}
      let position = []

      if (event.location) {
        position = await fetchLocationPosition(event.location)
      }

      handleRowSelect(event, selectedEvents, setSelectedEvents)
      setModalState({
        show: true,
        type: user.group === 'admin' ? 'edit' : 'view',
        isEditing: user.group === 'admin',
        location: !!event.location,
        content: {
          id: event.id,
          name: event.name,
          details: event.description,
          start_time: formatTimeForInput(new Date(event.start_time)),
          end_time: formatTimeForInput(new Date(event.end_time)),
          rrule: event.rrule || '',
          location: event.location && {
            position: position,
            address: event.location,
          },
          rruleObj,
          invitees: event.invitees || [],
        },
        startDate: new Date(event.start_time),
        endDate: new Date(event.end_time),
      })
    }
  }

  const handleShareClick = () => {
    setModalState({
      show: true,
      type: 'share',
    })
  }

  const handleDeleteClick = () => {
    setModalState({
      ...modalState,
      show: true,
      type: 'delete',
      deletionMode: '',
    })
  }

  const handleUpdateClick = () => {
    setModalState({
      ...modalState,
      show: true,
      type: 'update',
      updateMode: '',
    })
  }

  const handleCloseModal = () => {
    setSelectedRecipients([])
    setModalState({
      show: false,
      isEditing: false,
      content: {
        id: null,
        name: '',
        details: '',
        start_time: '12:00',
        end_time: '13:00',
        rrule: '',
        rruleObj: {},
        location: {
          position: [],
          address: '',
        },
        invitees: [],
      },
      date: new Date(),
    })
  }

  // location
  const fetchSuggestions = useCallback(
    debounce((value) => {
      fetch(`https://nominatim.openstreetmap.org/search?q=${value}&format=json&limit=5`)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const parsedData = data.map((item) => {
              const {display_name, ...rest} = item
              const parsedAddress = parseAddressSugg(display_name)
              return {
                ...rest,
                display_name: parsedAddress,
              }
            })
            setModalState((prevState) => ({
              ...prevState,
              suggestions: parsedData,
            }))
          }
        })
        .catch((error) => {
          console.error('Error fetching geocode data:', error)
        })
    }, 300),
    []
  )

  const handleSuggestionSelect = (suggestion) => {
    const {lat, lon, display_name: location} = suggestion
    console.log(lat)
    console.log(lon)
    console.log(location)

    setModalState((prevState) => ({
      ...prevState,
      content: {
        ...prevState.content,
        location: {
          position: [parseFloat(lat), parseFloat(lon)],
          address: location,
        },
      },
      markerPosition: [parseFloat(lat), parseFloat(lon)],
      suggestions: [],
    }))
  }

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='section-header'>
          <h1>Schedule</h1>
        </div>
        <div className='section-body'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <FullCalendar
                    ref={calendarRef}
                    timeZone='local'
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView='dayGridMonth'
                    events={filteredEvents.map((event) => ({
                      id: event.id,
                      title: event.name,
                      start: new Date(event.start_time),
                      end: new Date(event.end_time),
                      description: event.description,
                    }))}
                    height='680px'
                    dayCellClassNames={(arg) =>
                      ['fc-day', hoveredDate === arg.date.toISOString().split('T')[0] && 'fc-day-hover'].filter(Boolean)
                    }
                    dateClick={handleDateClick}
                    eventClick={handleEventClick}
                    dayCellDidMount={(info) => {
                      info.el.addEventListener('mouseenter', () => handleDateMouseEnter(info.date))
                      info.el.addEventListener('mouseleave', handleDateMouseLeave)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header d-flex align-items-center row w-100'>
                  <div className='col'>
                    <h4>Events</h4>
                  </div>
                  <div className='col-auto'>
                    <div className='card-header-form'>
                      <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-between align-items-center m-0 p-0'>
                  <div style={{overflowX: 'auto'}}>{eventButtons}</div>
                  <div className='input-group-btn d-flex justify-content-end align-items-center mt-3 mb-0' style={{marginRight: '38px'}}>
                    {selectedEvents.length > 0 && <h6 className='mt-2 mr-3 mb-0'>{selectedEvents.length} selected</h6>}
                    {selectedEvents.length > 0 && user.group === 'admin' && (
                      <i className='fas fa-times text-danger mt-1 mr-3' style={{fontSize: '1.5rem'}} onClick={handleDeleteClick}></i>
                    )}
                    {user.group === 'admin' && (
                      <i
                        className='fas fa-plus text-primary mt-1 mr-3'
                        style={{fontSize: '1.3rem'}}
                        onClick={() => handleDateClick({date: new Date()})}
                      ></i>
                    )}
                    <i
                      className='fas fa-external-link-alt text-primary mt-0'
                      style={{fontSize: '1.2rem'}}
                      onClick={() => handleShareClick()}
                    ></i>
                  </div>
                </div>
                <div
                  className='card-body'
                  style={{...cardBodyStyle, maxHeight: '800px'}}
                  ref={(el) => {
                    if (el) el.scrollLeft = 10
                  }}
                >
                  <table className='table table-hover'>
                    <thead>
                      <tr>
                        <th scope='col'>#</th>
                        <th scope='col' className='text-nowrap'>
                          Name
                        </th>
                        <th scope='col' className='text-nowrap'>
                          Starts
                        </th>
                        <th scope='col' className='text-nowrap'>
                          Ends
                        </th>
                        <th scope='col'>Location</th>
                        {user.group === 'admin' && (
                          <th scope='col' className='text-nowrap'>
                            Check - Ins
                          </th>
                        )}
                        {user.group === 'admin' && <th scope='col'>User</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredEvents.map((event, index) => (
                        <tr
                          key={event.id}
                          onClick={() => handleRowSelect(event, selectedEvents, setSelectedEvents)}
                          onMouseDown={() => handleMouseDown(setIsDragging)}
                          onMouseUp={() => handleMouseUp(setIsDragging)}
                          onMouseEnter={() => handleMouseEnter(event, isDragging, selectedEvents, setSelectedEvents)}
                          className={selectedEvents.some((e) => e.id === event.id) ? 'table-secondary' : ''}
                        >
                          <th scope='row' data-label='#'>
                            {index + 1}
                          </th>
                          <td data-label='Name' className='text-nowrap'>
                            {event.name}
                          </td>
                          <td data-label='Starts' className='text-nowrap'>
                            {new Date(event.start_time).toLocaleString([], {
                              dateStyle: 'short',
                              timeStyle: 'short',
                            })}
                          </td>
                          <td data-label='Ends' className='text-nowrap'>
                            {new Date(event.end_time).toLocaleString([], {
                              dateStyle: 'short',
                              timeStyle: 'short',
                            })}
                          </td>
                          <td data-label='Location'>{event.location ? splitAddress(event.location) : ''}</td>
                          {user.group === 'admin' && event.invitees && (
                            <td data-label='CheckIn'>
                              {event.invitees.map((invitee, i) => (
                                <div key={i} className='d-flex flex-column justify-content-start text-nowrap mb-1'>
                                  <div data-label='Invitee' className='d-flex flex-column'>
                                    {invitee.user || ''}
                                  </div>
                                  <div className='d-flex flex-column'>
                                    {invitee.checkin_time &&
                                      `In: ${new Date(invitee.checkin_time).toLocaleString([], {
                                        dateStyle: 'short',
                                        timeStyle: 'short',
                                      })}`}
                                    <br />
                                    {invitee.checkout_time &&
                                      `Out: ${new Date(invitee.checkout_time).toLocaleString([], {
                                        dateStyle: 'short',
                                        timeStyle: 'short',
                                      })}`}
                                  </div>
                                </div>
                              ))}
                            </td>
                          )}
                          {user.group === 'admin' && <td data-label='User'>{event.user || 'N/A'}</td>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {modalState.show && modalState.type === 'edit' && user.group === 'admin' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{modalState.isEditing ? 'Edit Event' : 'Add Event'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className='mb-3'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  name='name'
                  value={modalState.content.name}
                  onChange={handleInputChange}
                  placeholder='Enter event name'
                  required
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Details</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  name='details'
                  value={modalState.content.details}
                  onChange={handleInputChange}
                  placeholder='Enter event details'
                  style={{minHeight: '120px'}}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Starts</Form.Label>
                <div className='d-flex'>
                  <Form.Control
                    type='date'
                    name='startDate'
                    value={formatDateForInput(modalState.startDate)}
                    onChange={handleInputChange}
                    required
                    className='mr-2'
                  />
                  <Form.Control type='time' name='start_time' value={modalState.content.start_time} onChange={handleInputChange} required />
                </div>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Ends</Form.Label>
                <div className='d-flex'>
                  <Form.Control
                    type='date'
                    name='endDate'
                    value={formatDateForInput(modalState.endDate)}
                    onChange={handleInputChange}
                    required
                    className='mr-2'
                  />
                  <Form.Control type='time' name='end_time' value={modalState.content.end_time} onChange={handleInputChange} required />
                </div>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Check
                  type='checkbox'
                  label='Location'
                  checked={!!modalState.location}
                  onChange={(e) => {
                    const checked = e.target.checked
                    setModalState((prevState) => ({
                      ...prevState,
                      location: checked,
                    }))
                  }}
                />
              </Form.Group>
              {modalState.location && (
                <>
                  <div className='rounded-3 overflow-hidden mb-3'>
                    <Map
                      height={400}
                      center={(modalState.content.location && modalState.content.location.position) || [40.776676, -73.971321]}
                      defaultZoom={11}
                    >
                      <Marker
                        width={50}
                        anchor={(modalState.content.location && modalState.content.location.position) || [40.776676, -73.971321]}
                      />
                    </Map>
                  </div>

                  <Form.Group className='mb-3'>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type='text'
                      name='location'
                      value={modalState.content.location && modalState.content.location.address}
                      onChange={handleInputChange}
                      placeholder='Enter event address'
                      required
                    />
                  </Form.Group>
                  {modalState.suggestions && (
                    <ListGroup>
                      {modalState.suggestions.map((suggestion, index) => (
                        <ListGroup.Item key={index} onClick={() => handleSuggestionSelect(suggestion)}>
                          {suggestion.display_name}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </>
              )}
              <Form.Group className='mb-3'>
                <Form.Check
                  type='checkbox'
                  label='Repeat'
                  checked={!!modalState.content.rrule}
                  onChange={(e) => {
                    const checked = e.target.checked
                    setModalState((prevState) => ({
                      ...prevState,
                      content: {
                        ...prevState.content,
                        rrule: checked ? 'FREQ=DAILY' : '',
                        rruleObj: checked ? {FREQ: 'DAILY', INTERVAL: '1', UNTIL: '', BYDAY: []} : {},
                      },
                    }))
                  }}
                />
              </Form.Group>
              {modalState.content.rrule && (
                <>
                  <Form.Group className='mb-3'>
                    <Form.Label>Frequency</Form.Label>
                    <Form.Control
                      as='select'
                      name='FREQ'
                      value={modalState.content.rruleObj.FREQ || 'DAILY'}
                      onChange={handleRRuleChange}
                      required
                    >
                      <option value='DAILY'>Daily</option>
                      <option value='WEEKLY'>Weekly</option>
                      <option value='MONTHLY'>Monthly</option>
                      <option value='YEARLY'>Yearly</option>
                    </Form.Control>
                  </Form.Group>
                  {modalState.content.rruleObj.FREQ === 'WEEKLY' && (
                    <Form.Group className='mb-3'>
                      <Form.Label>Select Days of the Week</Form.Label>
                      <div className='d-flex flex-wrap'>
                        {['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'].map((day, index) => (
                          <Form.Check
                            key={index}
                            inline
                            type='checkbox'
                            label={day}
                            value={day}
                            checked={modalState.content.rruleObj.BYDAY?.includes(day) || false}
                            onChange={handleDayOfWeekChange}
                          />
                        ))}
                      </div>
                    </Form.Group>
                  )}
                  <Form.Group className='mb-3'>
                    <Form.Label>Interval</Form.Label>
                    <Form.Control
                      as='select'
                      name='INTERVAL'
                      value={modalState.content.rruleObj.INTERVAL || '1'}
                      onChange={handleRRuleChange}
                      required
                    >
                      {[...Array(99).keys()].map((i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>End Repeat</Form.Label>
                    <Form.Control
                      type='date'
                      name='UNTIL'
                      value={modalState.content.rruleObj.UNTIL || ''}
                      onChange={handleRRuleChange}
                      required
                    />
                  </Form.Group>
                </>
              )}
              <Form.Group className='mb-3'>
                <Form.Label>Assign</Form.Label>
                <Form.Select
                  value=''
                  onChange={(e) => {
                    const selectedInviteeId = e.target.value
                    if (selectedInviteeId) {
                      handleInviteeChange(selectedInviteeId, 'assign')
                    }
                  }}
                >
                  <option value='' disabled>
                    Select an invitee
                  </option>
                  {recipients
                    .filter((recipient) => recipient.group === 'employee')
                    .map((filteredRecipient) => {
                      return (
                        <option key={filteredRecipient.id} value={filteredRecipient.id}>
                          {filteredRecipient.name}
                        </option>
                      )
                    })}
                </Form.Select>

                <div className='mt-2'>
                  {(modalState.content.invitees || [])
                    .filter((invitee) => !invitee.deleted)
                    .map((invitee, index) => (
                      <button
                        key={index}
                        type='button'
                        className='btn btn-primary btn-sm rounded-pill my-1 me-2 align-items-center justify-content-center'
                        onClick={() => handleInviteeChange(invitee.user_id, 'remove')}
                      >
                        <i className='fas fa-times me-1' />
                        {invitee.user}
                      </button>
                    ))}
                </div>
              </Form.Group>

              <div className='d-flex justify-content-end mt-3'>
                {modalState.isEditing && (
                  <Button variant='danger' onClick={handleDeleteClick} className='mr-2'>
                    Delete
                  </Button>
                )}
                <Button variant='primary' type='submit'>
                  {modalState.isEditing ? 'Save Event' : 'Add Event'}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      {modalState.show && modalState.type === 'view' && user.group !== 'admin' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{'View Event'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'>
              <strong className='fs-6'>Name</strong>
              <p className='fs-6'>{modalState.content.name}</p>
            </div>
            <div className='mb-3'>
              <strong className='fs-6'>Details</strong>
              <p className='fs-6'>{modalState.content.details}</p>
            </div>
            <div className='d-flex justify-content-start column mb-1'>
              <div className='d-flex flex-column me-5'>
                <strong className='fs-6'>Starts</strong>
                <p className='fs-6'>
                  {convertUTCToLocalTime(formatDateWithTime(modalState.startDate, modalState.content.start_time)).toLocaleString([], {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  })}
                </p>
              </div>
              <div className='d-flex flex-column'>
                <strong className='fs-6'>Ends</strong>
                <p className='fs-6'>
                  {convertUTCToLocalTime(formatDateWithTime(modalState.endDate, modalState.content.end_time)).toLocaleString([], {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  })}
                </p>
              </div>
            </div>
            {modalState.location && (
              <div className='mb-3'>
                <strong className='fs-6'>Location</strong>
                <div className='rounded-3 overflow-hidden mt-2 mb-2'>
                  <Map
                    height={400}
                    center={(modalState.content.location && modalState.content.location.position) || [40.776676, -73.971321]}
                    defaultZoom={11}
                  >
                    <Marker
                      width={50}
                      anchor={(modalState.content.location && modalState.content.location.position) || [40.776676, -73.971321]}
                    />
                  </Map>
                </div>
                <div className='mb-3'>
                  <strong className='fs-6'>Address</strong>
                  <p className='fs-6'>{modalState.content.location?.address}</p>
                </div>
              </div>
            )}
            {modalState.content.rrule && (
              <div className='mb-3'>
                <strong className='fs-6'>Repeats</strong>
                <p className='fs-6'>
                  {`This event occurs every ${modalState.content.rruleObj.INTERVAL} ${modalState.content.rruleObj.FREQ.toLowerCase()}`}
                  {modalState.content.rruleObj.FREQ === 'WEEKLY' && ` on ${daysOfWeek(modalState.content.rruleObj.BYDAY)}`}
                  {` until ${new Date(modalState.content.rruleObj.UNTIL).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}.`}
                </p>
              </div>
            )}
            {modalState.content.invitees && (
              <div className='mb-3'>
                <strong className='fs-6'>Assigned</strong>

                <div className='mt-2'>
                  {(modalState.content.invitees || []).map((invitee, index) => (
                    <div className='d-flex align-items-center'>
                      <i className='fas fa-circle me-2 mb-n1' style={{fontSize: '0.35rem'}}></i>
                      <p className='mb-n1'>{invitee.user}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}
      {modalState.show && modalState.type === 'share' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered size='sm'>
          <Modal.Header closeButton>
            <Modal.Title>{`Share ${selectedEvents.length > 1 ? `${selectedEvents.length} Events` : 'Event'}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId='formRecipients'>
                <Form.Label>Recipients {selectedRecipients.length > 0 && `+${selectedRecipients.length} selected`}</Form.Label>
              </Form.Group>
              <Form.Group controlId='formSearch'>
                <Form.Control
                  type='text'
                  placeholder='Search Recipients'
                  value={shareSearch}
                  onChange={(e) => setShareSearch(e.target.value)}
                  className='mb-2'
                />
              </Form.Group>
              <div style={{height: '220px', overflowY: 'auto'}}>
                {getFilteredData(recipients, shareSearch)
                  .sort((a, b) => {
                    const aSelected = selectedRecipients.includes(a.id)
                    const bSelected = selectedRecipients.includes(b.id)
                    if (aSelected === bSelected) return 0
                    return aSelected ? -1 : 1
                  })
                  .map((recipient) => {
                    const isSelected = selectedRecipients.includes(recipient.id)
                    return (
                      <div
                        key={recipient.id}
                        className='fs-6 mb-2'
                        onClick={() => {
                          if (isSelected) {
                            setSelectedRecipients(selectedRecipients.filter((id) => id !== recipient.id))
                          } else {
                            setSelectedRecipients([...selectedRecipients, recipient.id])
                          }
                        }}
                      >
                        {recipient.name}
                        {isSelected && <i className='fas fa-times text-danger ml-2'></i>}
                      </div>
                    )
                  })}
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='primary'
              onClick={() => handleShare(selectedRecipients)}
              disabled={selectedEvents.length < 1 || selectedRecipients.length < 1}
            >
              Share
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {modalState.show && modalState.type === 'delete' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Event</Modal.Title>
          </Modal.Header>
          <Modal.Body className='d-flex flex-column align-items-center'>
            <Button
              variant='secondary'
              size='lg'
              className='mb-3 w-100'
              onClick={() => {
                setModalState({...modalState, deletionMode: 'one'})
                handleDeleteSelected('one')
              }}
            >
              Delete This Event
            </Button>
            <Button
              variant='danger'
              size='lg'
              className='w-100'
              onClick={() => {
                setModalState({...modalState, deletionMode: 'all'})
                handleDeleteSelected('all')
              }}
            >
              Delete All Future Events
            </Button>
          </Modal.Body>
        </Modal>
      )}
      {modalState.show && modalState.type === 'update' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update Event</Modal.Title>
          </Modal.Header>
          <Modal.Body className='d-flex flex-column align-items-center'>
            <Button
              variant='primary'
              size='lg'
              className='mb-3 w-100'
              onClick={() => {
                setModalState({...modalState, updateMode: 'one'})
                handleUpdateSelected('one')
              }}
            >
              Update This Event
            </Button>
            <Button
              variant='primary'
              size='lg'
              className='w-100'
              onClick={() => {
                setModalState({...modalState, updateMode: 'all'})
                handleUpdateSelected('all')
              }}
            >
              Update All Future Events
            </Button>
          </Modal.Body>
        </Modal>
      )}
      <style jsx>{`
        .fc-day-hover {
          background-color: #e6e6e6; /* Light grey background color */
          cursor: pointer;
        }
      `}</style>
    </div>
  )
}

export default Calendar
