import React, {useEffect, useState} from 'react'
import {Modal, Form, Button} from 'react-bootstrap'
import {useAuth} from '../../contexts/authContext'
import {getRecipientsByUserId, getDocumentsByUserId, getEventsByUserId, updateEvent, fetchLocationPosition} from '../../contexts/service'
import {isInRadius, UserCard, CheckInCard, DocumentPreview, cardStyle, cardBodyStyle} from '../utils'

const DashStaff = () => {
  const {user} = useAuth()
  const [recipients, setRecipients] = useState([])
  const [location, setLocation] = useState({latitude: null, longitude: null})
  const [error, setError] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [document, setDocument] = useState(null)
  const [event, setEvent] = useState(null)
  const [info, setInfo] = useState({
    feed: [],
    sleep: [],
    diapers: [],
  })
  const [tempRecord, setTempRecord] = useState({
    feed: {time: '', nurse: '', feedOz: '', pumpOz: ''},
    sleep: {start: '', end: ''},
    diapers: {time: '', wet: false, dirty: false},
  })
  useEffect(() => {
    if (user) {
      fetchData()
    }
  }, [user])

  const fetchData = async () => {
    Promise.all([getRecipientsByUserId(user.id), getDocumentsByUserId(user.id), getEventsByUserId(user.id)])
      .then(([recipientData, documentData, eventResponse]) => {
        if (recipientData) {
          const filteredRecipients = recipientData.filter((recipient) => recipient.name.toLowerCase() !== 'admin')
          setRecipients(filteredRecipients)
        }

        if (documentData && documentData.length > 0) {
          const latestDocument = documentData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
          setDocument(latestDocument)
        }

        if (eventResponse && eventResponse.related) {
          const relatedEvents = eventResponse.related
          const now = new Date()

          const futureRelatedEvents = relatedEvents.filter((event) => new Date(event.end_time) > now)

          if (futureRelatedEvents.length > 0) {
            let nextEvent = futureRelatedEvents.sort((a, b) => new Date(a.end_time) - new Date(b.end_time))[0]
            nextEvent.invitees = nextEvent.invitees?.filter((invitee) => invitee.user_id === user.id) || []

            fetchLocationPosition(nextEvent.location)
              .then((position) => {
                nextEvent.location = {
                  address: nextEvent.location,
                  position: position,
                }
                setEvent(nextEvent)
              })
              .catch(() => {
                nextEvent.location = {
                  address: nextEvent.location,
                  position: null,
                }
                setEvent(nextEvent)
              })
          }
        }
      })
      .catch(() => {
        console.log('No data.')
      })
  }

  const handleCheckIn = async (checkOut = false) => {
    if ('geolocation' in navigator) {
      const now = new Date()

      console.log('event end time', event?.end_time)
      console.log('current time:', now)

      const tenMinutesBeforeEvent = new Date(event?.start_time)
      if (tenMinutesBeforeEvent) {
        tenMinutesBeforeEvent.setMinutes(tenMinutesBeforeEvent.getMinutes() - 10)
      }

      const eventEndTime = new Date(event?.end_time)

      if (checkOut || (now >= tenMinutesBeforeEvent && now <= eventEndTime)) {
        const useMockLocation = true
        if (useMockLocation) {
          const latitude = 40.66831
          const longitude = -73.61513
          const radius = 0.10668

          setError(null)

          if (event.location) {
            const [eventLat, eventLon] = event.location.position

            if (isInRadius(latitude, longitude, eventLat, eventLon, radius)) {
              setLocation({latitude, longitude})
              const timeField = checkOut ? 'checkout_time' : 'checkin_time'
              updateEvent(
                {
                  id: event.id,
                  invitees: event.invitees
                    .map((invitee) => (invitee.user_id === user.id ? {...invitee, [timeField]: new Date().toISOString()} : invitee))
                    .filter(Boolean),
                },
                user.id
              ).then(() => fetchData())
            } else {
              setError('You are too far from the event')
            }
          }
        } else {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latitude = position.coords.latitude
              const longitude = position.coords.longitude
              const radius = 0.10668

              setError(null)

              if (event.location) {
                const [eventLat, eventLon] = event.location.position

                if (isInRadius(latitude, longitude, eventLat, eventLon, radius)) {
                  setLocation({latitude, longitude})
                  const timeField = checkOut ? 'checkout_time' : 'checkin_time'
                  updateEvent(
                    {
                      id: event.id,
                      invitees: event.invitees
                        .map((invitee) => (invitee.user_id === user.id ? {...invitee, [timeField]: new Date().toISOString()} : invitee))
                        .filter(Boolean),
                    },
                    user.id
                  ).then(() => fetchData())
                } else {
                  setError('You are too far from the event')
                }
              }
            },
            handleGeolocationError,
            {
              enableHighAccuracy: true,
              timeout: 10000,
            }
          )
        }
      } else {
        setError('Check-in is only allowed within 10 minutes of event.')
      }
    } else {
      setError('Geolocation is not supported by your browser.')
    }
  }

  const handleGeolocationError = (error) => {
    let errorMessage
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorMessage = (
          <span>
            You have denied access to location services.
            <br />
            Please enable location access in your browser settings and click the button again.
          </span>
        )
        break
      case error.POSITION_UNAVAILABLE:
        errorMessage = 'Location information is unavailable. Please check your network or location settings and try again.'
        break
      case error.TIMEOUT:
        errorMessage = 'The request to get user location timed out. Please try again.'
        break
      default:
        errorMessage = 'An unknown error occurred. Please try again.'
    }
    setError(errorMessage)
  }

  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  const handleInputChange = (type, field, value) => {
    setTempRecord({
      ...tempRecord,
      [type]: {
        ...tempRecord[type],
        [field]: value,
      },
    })
  }

  const handleCheckboxChange = (type, field, checked) => {
    setTempRecord({
      ...tempRecord,
      [type]: {
        ...tempRecord[type],
        [field]: checked,
      },
    })
  }

  const handleAddRecord = (type) => {
    setInfo((prevInfo) => ({
      ...prevInfo,
      [type]: [...prevInfo[type], tempRecord[type]],
    }))

    // Clear the input fields for the given type
    setTempRecord((prevTempRecord) => ({
      ...prevTempRecord,
      [type]:
        type === 'feed'
          ? {time: '', nurse: '', feedOz: '', pumpOz: ''}
          : type === 'sleep'
          ? {start: '', end: ''}
          : {time: '', wet: false, dirty: false},
    }))
  }

  const handleNurseClick = () => {
    // Define the options array inline within the function
    const options = ['L', 'R', 'LR', '']
    const currentValue = tempRecord.feed.nurse
    const currentIndex = options.indexOf(currentValue)
    // Calculate the next index (cycle back to 0 if at the end)
    const nextIndex = (currentIndex + 1) % options.length
    const nextValue = options[nextIndex]
    // Update the state with the next value
    handleInputChange('feed', 'nurse', nextValue)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Combine the tempRecord into info even if they are incomplete.
    const newInfo = {
      feed: [...info.feed, tempRecord.feed].filter((record) => Object.values(record).some((value) => value !== '' && value !== false)),
      sleep: [...info.sleep, tempRecord.sleep].filter((record) => Object.values(record).some((value) => value !== '')),
      diapers: [...info.diapers, tempRecord.diapers].filter((record) =>
        Object.values(record).some((value) => value !== '' && value !== false)
      ),
    }
    console.log('Submitted data:', newInfo)

    // Clear info and tempRecord after submission
    setInfo({
      feed: [],
      sleep: [],
      diapers: [],
    })
    setTempRecord({
      feed: {time: '', nurse: '', feedOz: '', pumpOz: ''},
      sleep: {start: '', end: ''},
      diapers: {time: '', wet: false, dirty: false},
    })
  }

  const handleClear = () => {
    setTempRecord({
      feed: {time: '', nurse: '', feedOz: '', pumpOz: ''},
      sleep: {start: '', end: ''},
      diapers: {time: '', wet: false, dirty: false},
    })
    setInfo({
      feed: [],
      sleep: [],
      diapers: [],
    })
    console.log('Cleared data')
  }

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='row'>
          <UserCard data={recipients} title='Client Info' />
          <CheckInCard handleCheckIn={handleCheckIn} event={event} location={location} error={error} />
        </div>

        <div className='row'>
          <DocumentPreview name={user.name} document={document} />

          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div onSubmit={handleSubmit} className='card card-statistic-2' style={{cardStyle}}>
              <Form>
                <div className='card-header'>
                  <div className='d-flex justify-content-between'>
                    <h4>Activities Info</h4>
                    {tempRecord && <i className='fas fa-times' type='button' onClick={handleClear} />}
                  </div>
                </div>
                <div className='card-body mb-3' style={{cardBodyStyle}}>
                  {/* Feeding Section */}
                  <Form.Group className='mt-2 mb-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <Form.Label className='fs-6'>Feeding</Form.Label>
                      {info.feed.length > 0 && (
                        <p className='fs-6'>
                          +{info.feed.length} {info.feed.length === 1 ? 'Record' : 'Records'}
                        </p>
                      )}
                    </div>
                    <div className='d-flex align-items-end'>
                      <div className='me-2 flex-fill'>
                        <Form.Label className='fs-6'>Time</Form.Label>
                        <Form.Control
                          type='time'
                          value={tempRecord.feed.time}
                          onChange={(e) => handleInputChange('feed', 'time', e.target.value)}
                        />
                      </div>
                      <div className='me-2 flex-fill'>
                        <Form.Label className='fs-6'>Nurse</Form.Label>
                        <Button
                          variant='outline-primary'
                          onClick={handleNurseClick}
                          className='text-uppercase'
                          style={{height: '40px', minWidth: '50px'}}
                          aria-label={`Nurse side selected: ${tempRecord.feed.nurse || 'None'}. Click to toggle.`}
                        >
                          {tempRecord.feed.nurse || ' '}
                        </Button>
                      </div>
                      <div className='me-2 flex-fill'>
                        <Form.Label className='fs-6'>Feed</Form.Label>
                        <Form.Control
                          type='number'
                          value={tempRecord.feed.feedOz}
                          min='0'
                          placeholder='oz/ml'
                          onChange={(e) => handleInputChange('feed', 'feedOz', e.target.value)}
                        />
                      </div>
                      <div className='me-2 flex-fill'>
                        <Form.Label className='fs-6'>Pump</Form.Label>
                        <Form.Control
                          type='number'
                          value={tempRecord.feed.pumpOz}
                          min='0'
                          placeholder='oz/ml'
                          onChange={(e) => handleInputChange('feed', 'pumpOz', e.target.value)}
                        />
                      </div>
                      <div>
                        <button type='button' className='btn btn-secondary mb-1' onClick={() => handleAddRecord('feed')}>
                          <i className='fas fa-plus'></i>
                        </button>
                      </div>
                    </div>
                  </Form.Group>

                  {/* Sleep Section */}
                  <Form.Group className='mb-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <Form.Label className='fs-6'>Sleeping</Form.Label>
                      {info.sleep.length > 0 && (
                        <p className='fs-6'>
                          +{info.sleep.length} {info.sleep.length === 1 ? 'Record' : 'Records'}
                        </p>
                      )}
                    </div>
                    <div className='d-flex align-items-end'>
                      <div className='me-2 flex-fill'>
                        <Form.Label className='fs-6'>Start</Form.Label>
                        <Form.Control
                          type='time'
                          value={tempRecord.sleep.start}
                          onChange={(e) => handleInputChange('sleep', 'start', e.target.value)}
                        />
                      </div>
                      <div className='me-2 flex-fill'>
                        <Form.Label className='fs-6'>End</Form.Label>
                        <Form.Control
                          type='time'
                          value={tempRecord.sleep.end}
                          onChange={(e) => handleInputChange('sleep', 'end', e.target.value)}
                        />
                      </div>
                      <div>
                        <button type='button' className='btn btn-secondary mb-1' onClick={() => handleAddRecord('sleep')}>
                          <i className='fas fa-plus'></i>
                        </button>
                      </div>
                    </div>
                  </Form.Group>

                  {/* Diapers Section */}
                  <Form.Group className='mb-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <Form.Label className='fs-6'>Diapers</Form.Label>
                      {info.diapers.length > 0 && (
                        <p className='fs-6'>
                          +{info.diapers.length} {info.diapers.length === 1 ? 'Record' : 'Records'}
                        </p>
                      )}
                    </div>
                    <div className='d-flex align-items-end'>
                      <div className='me-2 flex-fill'>
                        <Form.Label className='fs-6'>Time</Form.Label>
                        <Form.Control
                          type='time'
                          value={tempRecord.diapers.time}
                          onChange={(e) => handleInputChange('diapers', 'time', e.target.value)}
                        />
                      </div>
                      <div className='me-2 flex-fill'>
                        <Form.Check
                          type='checkbox'
                          label='Wet'
                          checked={tempRecord.diapers.wet}
                          onChange={(e) => handleCheckboxChange('diapers', 'wet', e.target.checked)}
                        />
                      </div>
                      <div className='me-2 flex-fill'>
                        <Form.Check
                          type='checkbox'
                          label='Dirty'
                          checked={tempRecord.diapers.dirty}
                          onChange={(e) => handleCheckboxChange('diapers', 'dirty', e.target.checked)}
                        />
                      </div>
                      <div>
                        <button type='button' className='btn btn-secondary mb-1' onClick={() => handleAddRecord('diapers')}>
                          <i className='fas fa-plus'></i>
                        </button>
                      </div>
                    </div>
                  </Form.Group>

                  <button className='btn btn-primary w-100' type='submit'>
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default DashStaff
