import React from 'react'
import {stateAbbreviations} from './const'

// css
export const cardStyle = {
  height: '520px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
}

export const cardBodyStyle = {
  overflowY: 'auto',
  overflowX: 'auto',
}

// validation
export const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  return re.test(String(email).toLowerCase())
}

export const validatePhone = (phone) => {
  const re = /^[0-9]{10}$/
  return re.test(String(phone))
}

export const validateAddress = (address) => {
  if (!/^.{6,}$/.test(address.street)) {
    return 'Invalid street address'
  }
  if (!/^.{3,}$/.test(address.city)) {
    return 'Invalid city'
  }
  if (!/^[A-Z]{2}$/.test(address.state)) {
    return 'Invalid state'
  }
  if (!/^[0-9]{5}(-[0-9]{4})?$/.test(address.postalCode)) {
    return 'Invalid postal code'
  }
  return null
}

//format
export const formatDateWithTime = (date, time) => {
  const [hours, minutes] = time.split(':').map(Number)
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0)
}

export const formatDateForInput = (date) => {
  if (date instanceof Date && !isNaN(date)) {
    const year = date.getFullYear()
    const month = `${date.getMonth() + 1}`.padStart(2, '0')
    const day = `${date.getDate()}`.padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  return ''
}

export const formatTimeForInput = (date) => {
  if (date instanceof Date && !isNaN(date)) {
    const hours = date
      .getHours()
      .toString()
      .padStart(2, '0')
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0')
    return `${hours}:${minutes}`
  }
  return ''
}

export const formatPhone = (phoneString) => {
  if (!phoneString || phoneString.length !== 10) {
    return phoneString
  }

  return `(${phoneString.slice(0, 3)}) ${phoneString.slice(3, 6)} ${phoneString.slice(6)}`
}

export const formatAddress = (jsonString) => {
  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

  const isValidJsonString = (str) => {
    if (typeof str !== 'string') return false
    str = str.trim()
    return (str.startsWith('{') && str.endsWith('}')) || (str.startsWith('[') && str.endsWith(']'))
  }

  let address
  if (jsonString && isValidJsonString(jsonString)) {
    address = JSON.parse(jsonString)
  } else {
    return jsonString
  }

  if (address && address.street && address.city && address.state && address.postalCode) {
    return `${capitalizeFirstLetter(address.street)}, ${capitalizeFirstLetter(address.city)}, ${address.state.toUpperCase()} ${
      address.postalCode
    }`
  } else {
    return ''
  }
}

export const parseAddress = (addressString) => {
  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

  const addressPattern = /^(.*?), (.*?), ([A-Za-z]{2}) (\d{5})$/
  const match = addressPattern.exec(addressString.trim())

  if (match) {
    const address = {
      street: capitalizeFirstLetter(match[1].trim()),
      city: capitalizeFirstLetter(match[2].trim()),
      state: match[3].toUpperCase(),
      postalCode: match[4].trim(),
    }

    return JSON.stringify(address)
  } else {
    return null
  }
}

export const parseAddressSugg = (address) => {
  const parts = address.split(',').map((part) => part.trim())
  if (parts.length < 5) {
    console.error('Unexpected address format:', address)
    return address
  }

  const streetNumber = parts[0]
  const streetName = parts[1]
  const neighborhood = parts[2]
  const zipCodeMatch = parts.find((part) => /\d{5}(-\d{4})?$/.test(part))
  const zipCode = zipCodeMatch ? zipCodeMatch : ''
  const stateName = parts[parts.indexOf(zipCode) - 1] || ''
  const stateAbbreviation = stateAbbreviations[stateName] || stateName
  console.log(stateName)
  console.log(zipCode)
  return `${streetNumber} ${streetName}, ${neighborhood}, ${stateAbbreviation} ${zipCode}`
}

export const truncateText = (text) => {
  const maxLength = 14
  const suffix = '...'

  return text.length > maxLength ? text.substring(0, maxLength - suffix.length) + suffix : text
}

export const splitAddress = (address) => {
  const parts = address.split(',')
  if (parts.length < 3) {
    return <span className='text-nowrap'>{address.trim()}</span>
  }
  const firstPart = parts[0].trim()
  const secondPart = `${parts[1].trim()}, ${parts
    .slice(2)
    .join(',')
    .trim()}`
  return (
    <>
      <span className='text-nowrap'>{firstPart},</span>
      <br />
      <span className='text-nowrap'>{secondPart}</span>
    </>
  )
}

export const daysOfWeek = (days) => {
  const dayMap = {
    MO: 'Monday',
    TU: 'Tuesday',
    WE: 'Wednesday',
    TH: 'Thursday',
    FR: 'Friday',
    SA: 'Saturday',
    SU: 'Sunday',
  }

  return days.map((day) => dayMap[day]).join(', ')
}

export const formatTimeTo12Hour = (time) => {
  let [hours, minutes] = time.split(':').map(Number)
  const suffix = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12 // Convert hour to 12-hour format, keeping 12 as is

  return `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${suffix}`
}

export const formatActivities = (data) => {
  let formattedData = []

  // Format feed data
  if (Array.isArray(data.feed) && data.feed.length > 0) {
    const feedData = data.feed
      .map((feedEntry) => {
        return `${formatTimeTo12Hour(feedEntry.time)} ${feedEntry.nurse} ${feedEntry.feedOz} ${feedEntry.pumpOz}`
      })
      .join(', ')
    formattedData.push(`feed: ${feedData}`)
  }

  // Format sleep data
  if (Array.isArray(data.sleep) && data.sleep.length > 0) {
    const sleepData = data.sleep
      .map((sleepEntry) => {
        return `${formatTimeTo12Hour(sleepEntry.start)} - ${formatTimeTo12Hour(sleepEntry.end)}`
      })
      .join(', ')
    formattedData.push(`sleep: ${sleepData}`)
  }

  // Format diapers data
  if (Array.isArray(data.diapers) && data.diapers.length > 0) {
    const diapersData = data.diapers
      .map((diaperEntry) => {
        return `${formatTimeTo12Hour(diaperEntry.time)} ${diaperEntry.wet} ${diaperEntry.dirty}`
      })
      .join(', ')
    formattedData.push(`diapers: ${diapersData}`)
  }

  // Join all formatted data into a single string
  return formattedData.join('\n')
}

export const parseActivities = (dataStr) => {
  if (typeof dataStr !== 'string') {
    return {feeding: [], sleep: [], diapers: []}
  }

  const parseFeeding = (feedingStr) => {
    const feedings = feedingStr.split(',').map((f) => f.trim())
    const result = []

    feedings.forEach((feeding) => {
      const match = feeding.match(/(\d{2}:\d{2}\s[APM]{2})\s([RL])\s(\d+)\s(\d+)/)
      if (match) {
        const [, time, nurse, feedOz, pumpOz] = match
        result.push({
          time,
          nurse,
          feedOz,
          pumpOz,
        })
      }
    })

    return result
  }

  const parseSleep = (sleepStr) => {
    const sleeps = sleepStr.split(',').map((s) => s.trim())
    const result = []

    sleeps.forEach((sleep) => {
      const [start, end] = sleep.split('-').map((s) => s.trim())
      if (start && end) {
        result.push({start, end})
      }
    })

    return result
  }

  const parseDiapers = (diaperStr) => {
    const diapers = diaperStr.split(',').map((d) => d.trim())
    const result = []

    diapers.forEach((diaper) => {
      const match = diaper.match(/(\d{2}:\d{2}\s[APM]{2})\s(true|false)\s(true|false)/)
      if (match) {
        const [, time, wet, dirty] = match
        result.push({
          time,
          wet: wet === 'true',
          dirty: dirty === 'true',
        })
      }
    })

    return result
  }

  // Parsing the main data string
  const lines = dataStr.trim().split('\n')
  const result = {feeding: [], sleep: [], diapers: []}

  lines.forEach((line) => {
    const colonIndex = line.indexOf(':')
    if (colonIndex === -1) {
      console.warn(`No colon found in line: ${line}`)
      return
    }

    const key = line.substring(0, colonIndex).trim()
    const value = line.substring(colonIndex + 1).trim()

    if (key === 'feed') {
      result.feeding = parseFeeding(value)
    } else if (key === 'sleep') {
      result.sleep = parseSleep(value)
    } else if (key === 'diapers') {
      result.diapers = parseDiapers(value)
    } else {
      console.warn(`Unknown key encountered: ${key}`)
    }
  })

  return result
}

// conversion
export const convertUTCToLocalTime = (dateString) => {
  const utcDate = new Date(dateString)
  return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
}

export const isInRadius = (lat1, lon1, lat2, lon2, radius) => {
  const toRadians = (degrees) => degrees * (Math.PI / 180)
  const R = 6371

  const dLat = toRadians(lat2 - lat1)
  const dLon = toRadians(lon2 - lon1)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c

  return distance <= radius
}

// selection
export const handleRowSelect = (item, selectedItems, setSelectedItems) => {
  if (selectedItems.includes(item)) {
    setSelectedItems(selectedItems.filter((i) => i !== item))
  } else {
    setSelectedItems([...selectedItems, item])
  }
}

export const handleMouseDown = (setIsDragging) => {
  setIsDragging(true)
}

export const handleMouseUp = (setIsDragging) => {
  setIsDragging(false)
}

export const handleMouseEnter = (item, isDragging, selectedItems, setSelectedItems) => {
  if (isDragging) {
    handleRowSelect(item, selectedItems, setSelectedItems)
  }
}

export const handleDeleteSelectedItems = (selectedItems, deleteFunc) => {
  if (!selectedItems || selectedItems.length === 0) {
    console.error('No items selected for deletion')
    return
  }

  const itemIds = selectedItems.map((item) => item.id)

  deleteFunc(itemIds).catch((error) => {
    console.error('Error deleting items:', error)
  })
}

// cards
export const UserCard = ({data, title}) => (
  <div className='col-lg-6 col-md-6 col-sm-12'>
    <div className='card card-statistic-2' style={cardStyle}>
      <div className='card-header'>
        <h4>{title}</h4>
      </div>
      <div className='card-body' style={cardBodyStyle}>
        {data &&
          (Array.isArray(data) ? data : [data]).map((user) => (
            <div key={user.id || user.name} className='mt-2 d-flex flex-column'>
              <label className='fs-6'>Name</label>
              <p>{user.name}</p>
              <label className='fs-6'>Email</label>
              <p>{user.email}</p>
              <label className='fs-6'>Phone</label>
              <p>{formatPhone(user.phone)}</p>
              <label className='fs-6'>Address</label>
              <p>{formatAddress(user.address)}</p>
              <hr />
            </div>
          ))}
      </div>
    </div>
  </div>
)

export const DocumentPreview = ({name, document, handleShowModal}) => (
  <div className='col-lg-6 col-md-6 col-sm-12'>
    <div className='card card-statistic-2'>
      <div className='card-header mb-2'>
        <h4>Document Preview</h4>
      </div>
      <div className='card-body' style={cardBodyStyle}>
        {document ? (
          <div className='mt-2'>
            <h6 className='card-title'>{document.name}</h6>
            <h6 className='card-title'>{document.author === name ? `Sent by Me` : `Sent from ${document.author}`}</h6>
            <iframe
              src={`data:${document.mime_type};base64,${Buffer.from(document.content).toString('base64')}`}
              frameBorder='0'
              width='100%'
              height='300px'
              onClick={handleShowModal}
            ></iframe>
          </div>
        ) : null}
      </div>
    </div>
  </div>
)

export const CheckInCard = ({handleCheckIn, event, location, error}) => {
  return (
    <div className='col-lg-6 col-md-6 col-sm-12'>
      <div className='card card-statistic-2' style={cardStyle}>
        <div className='card-header'>
          <h4>Check - In</h4>
        </div>
        <div className='card-body' style={cardBodyStyle}>
          {event && event.location && (
            <>
              <label className='fs-6'>Name</label>
              <p>{event.name}</p>
              <div className='d-flex justify-content-start column mb-1'>
                <div className='d-flex flex-column me-4'>
                  <label className='fs-6'>Starts</label>
                  <p>
                    {new Date(event.start_time).toLocaleString([], {
                      dateStyle: 'short',
                      timeStyle: 'short',
                    })}
                  </p>
                </div>
                <div className='d-flex flex-column'>
                  <label className='fs-6'>Ends</label>
                  <p>
                    {new Date(event.end_time).toLocaleString([], {
                      dateStyle: 'short',
                      timeStyle: 'short',
                    })}
                  </p>
                </div>
              </div>
              <label className='fs-6'>Location</label>
              <p>{event.location.address}</p>
              <hr className='mt-2' />
              {event.invitees && event.invitees.length > 0 && (
                <>
                  <div className='d-flex justify-content-start column mb-1'>
                    <div className='d-flex flex-column me-5'>
                      <label className='fs-6'>Check-In</label>
                      <p>
                        {event.invitees[0].checkin_time &&
                          new Date(event.invitees[0].checkin_time).toLocaleString([], {
                            dateStyle: 'short',
                            timeStyle: 'short',
                          })}
                      </p>
                    </div>
                    <div className='d-flex flex-column'>
                      <label className='fs-6'>Check-Out</label>
                      <p>
                        {event.invitees[0].checkout_time &&
                          new Date(event.invitees[0].checkout_time).toLocaleString([], {
                            dateStyle: 'short',
                            timeStyle: 'short',
                          })}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {location && location.latitude && location.longitude ? (
            <div className='mt-2'>
              <p>Success </p>
            </div>
          ) : error ? (
            <div className='mt-2'>
              <p>{error}</p>
            </div>
          ) : null}
        </div>
        <div className='card-footer'>
          <button
            className='btn btn-primary w-100'
            onClick={() => handleCheckIn(event?.invitees[0]?.checkin_time != null)}
            disabled={!!error || event?.invitees[0]?.checkout_time}
          >
            {event?.invitees[0]?.checkin_time && !event?.invitees[0]?.checkout_time ? 'Check - Out' : 'Check - In'}
          </button>
        </div>
      </div>
    </div>
  )
}

export const InfoCard = ({data, title}) => {
  const parsedData = data ? parseActivities(data) : {}
  return (
    <div className='col-lg-6 col-md-6 col-sm-12'>
      <div className='card card-statistic-2' style={{cardStyle}}>
        <div className='card-header'>
          <h4>{title}</h4>
        </div>
        <div className='card-body' style={{cardBodyStyle}}>
          <div className='mt-2 d-flex flex-column'>
            <label className='fs-6'>Feeding</label>
            {parsedData.feeding?.map((feed, index) => (
              <div key={index} className='d-flex justify-content-between'>
                <div>
                  <label className='fs-6'>Time</label>
                  <p>{feed.time}</p>
                </div>
                <div>
                  <label className='fs-6'>Nurse</label>
                  <p>{feed.nurse}</p>
                </div>
                <div>
                  <label className='fs-6'>Feed</label>
                  <p>{feed.feedOz ? `${feed.feedOz} oz/ml` : ''}</p>
                </div>
                <div>
                  <label className='fs-6'>Pump</label>
                  <p>{feed.pumpOz ? `${feed.pumpOz} oz/ml` : ''}</p>
                </div>
              </div>
            ))}
            <hr />

            <label className='fs-6'>Sleep</label>
            {parsedData.sleep?.map((sleep, index) => (
              <div key={index} className='d-flex justify-content-between'>
                <div>
                  <label className='fs-6'>Start</label>
                  <p>{sleep.start}</p>
                </div>
                <div>
                  <label className='fs-6'>End</label>
                  <p>{sleep.end}</p>
                </div>
              </div>
            ))}
            <hr />

            <label className='fs-6'>Diapers</label>
            {parsedData.diapers?.map((diaper, index) => (
              <div key={index} className='d-flex justify-content-between'>
                <div>
                  <label className='fs-6'>Time</label>
                  <p>{diaper.time}</p>
                </div>
                <div>
                  <label className='fs-6'>Wet</label>
                  <p>{diaper.wet ? 'Wet' : 'Dry'}</p>
                </div>
                <div>
                  <label className='fs-6'>Dirty</label>
                  <p>{diaper.dirty ? 'Dirty' : 'Clean'}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
